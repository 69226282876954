
export default {
  props: {
    isLp: {
      type: Boolean,
      default: false,
    },
    isNewsletter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      solutions: [
        {
          label: "Site web",
          link: "web",
          blank: false,
        },
        {
          label: "Réseaux sociaux",
          link: "gestion-rs",
          blank: false,
        },
        {
          label: "Publicité sur Google",
          link: "seo",
          blank: false,
        },
        {
          label: "Logo et supports imprimés",
          link: "graphism",
          blank: false,
        },
        // {
        //   label: "Pack STARTER",
        //   link: "starter",
        //   blank: false,
        // },
      ],
      abouts: [
        {
          label: "FAQ",
          link: "faq",
          blank: false,
        },
        {
          label: "ALEO",
          link: "about",
          blank: false,
        },
        {
          label: "Recrutement",
          link: "https://jobs.aleo.agency/",
          blank: true,
        },
        {
          label: "Programme partenaire",
          link: "partner",
          blank: false,
        },
        {
          label: "Parrainer un pro",
          link: "parrainage",
          blank: false,
        },
        {
          label: "Nos standards publicitaires",
          link: "standards-publicitaires",
          blank: false,
        },
      ],
      mores: [
        {
          label: "Témoignages",
          link: "testimonials",
          blank: false,
        },
        {
          label: "Blog",
          link: "blog",
          blank: false,
        },
      ],
      networks: [
        {
          img: require("~/assets/img/logo-fb-purple.svg"),
          link: "https://www.facebook.com/aleo.agency/",
        },
        {
          img: require("~/assets/img/logo-insta-purple.svg"),
          link: "https://www.instagram.com/aleo.agency/?hl=fr",
        },
        {
          img: require("~/assets/img/logo-linkedin-purple.svg"),
          link: "https://www.linkedin.com/company/aleo-agency/",
        },
        {
          img: require("~/assets/img/logo-yt-purple.svg"),
          link: "https://www.youtube.com/channel/UC4GBqxVpyYLiZGHRiA2RbPQ",
        },
        {
          img: require("~/assets/img/logo-tiktok-purple.svg"),
          link: "https://www.tiktok.com/@aleo.agency",
        },
      ],
    };
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
